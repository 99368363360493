import angular from "angular"
import toastr from "toastr"
import { DigiLeanNgScope } from "@common/model/angularModel"
import * as customerService from "@common/services/customers/customerService"
import { Asset, AssetRoleType, AssetUser, UserShortInfo } from "@api"
import * as assetService from "@common/services/assetService"

interface IAssetParticipantsScope extends DigiLeanNgScope {
    users: UserShortInfo[]
    participants: AssetUser[]
    asset: Asset
    headline: any
    addViewer: (user: AssetUser) => void
    add: (user: AssetUser) => void
    remove: (user: AssetUser) => void
    ok: () => void
    cancel: () => void
}

const DigiLean = angular.module('DigiLean')
DigiLean.controller('assetParticipantsController', ['$scope', '$uibModalInstance', '$translate', 'assetId','boardId',
    function ($scope: IAssetParticipantsScope, $uibModalInstance, $translate, assetId, boardId: number) {

        $scope.participants = []
        var loadUsers = function () {
            customerService.getAllUsers().then(function (users) {
                $scope.users = users
                customerService.getUsersForAsset(assetId).then(function (assetUsers) {
                    $scope.participants = assetUsers
                    filterUsers()
                })
            })
        }
        loadUsers()

        function filterUsers() {
            if (!Array.isArray($scope.participants) || $scope.participants.length === 0)
                return

            $scope.users = $scope.users.filter(u => !$scope.participants.some(p => p.userId == u.userId))
        }

        var getAsset = function () {
            assetService.getById(assetId).then(function (asset) {
                $scope.asset = asset
            })
        }
        getAsset()

        function updateRole(user, role: AssetRoleType) {
            const matchingUser = $scope.participants.find(p => p.userId == user.userId)
            if (matchingUser) {
                matchingUser.assetRole = role
            } else {
                user.assetRole = role
                $scope.participants.unshift(user)
            }
            filterUsers()
        }

        $scope.addOwner = function (user) {
            updateRole(user, AssetRoleType.Owner);
        }

        $scope.addViewer = function (user) {
            updateRole(user, AssetRoleType.Viewer);
        }

        $scope.add = function (user) {
            updateRole(user, AssetRoleType.Member);
        }

        $scope.remove = function (user) {
            $scope.users.unshift(user)

            const matchingUser = $scope.participants.find(p => p.userId == user.userId)
            if (matchingUser) {
                const i = $scope.participants.indexOf(matchingUser)
                $scope.participants.splice(i, 1)
            }
        }

        $scope.ok = function () {
            if (boardId) {
                customerService.giveAccessToUsersForBoard(boardId, $scope.participants).then(showAccessGiven)
            } else {
                customerService.giveAccessToUsersForAsset(assetId, $scope.participants).then(showAccessGiven)
            }
        }

        function showAccessGiven() {
            $uibModalInstance.close()
            // Raise signalr event
            $translate('ADMIN_MESSAGE_ASSET_MEMBERS_MODIFIED').then(function (msg) {
                toastr.success(msg)
            }, function (translationId) {
                $scope.headline = translationId
            })
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }
    }])