angular
    .module('DigiLean')
    .directive("smartactionlistProgressCellSubTasks", ['$filter', '$translate','$uibModal','boardTaskService',
        function ($filter, $translate, $uibModal, boardTaskService) {
            return {
                templateUrl: 'smartactionlistProgressCellSubTasks.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    var taskId = null;

                    scope.options = [
                        { progress: 25, label: "20 %", color: "#0099DE"},
                        { progress: 50, label: "50 %", color: "#0099DE"},
                        { progress: 75, label: "75 %", color: "#0099DE"},
                        { progress: 100, label: "100 %", color: "#8CC152"},
                    ]

                    scope.customOption = {
                        progress: 0,
                        label: "0 %",
                        color: "0099DE"
                    };
                    scope.$watch("model", function (model) {
                        
                        if (scope.model) {
                            taskId = scope.model.task.id;
                            calculateProgress();
                            scope.model.getSortValue = function() {
                                return scope.progress;
                            }
                        }
                       
                    });

                    scope.subscribe('TaskStatusUpdated', function (updatedstatus) {
                        if (scope.model.task.id == updatedstatus.taskId) {
                            scope.model.task.status = updatedstatus.status;
                            calculateProgress();
                        }
                    });

                    scope.subscribe("SubTaskCountChanged", function(count) {
                        if (count.taskId == taskId || count.taskId == scope.model.task.siblingId) {
                            scope.model.task.subTaskCount = count.subTaskCount;
                            scope.model.task.subTaskCompletedCount = count.subTaskCompletedCount;
                            calculateProgress();
                        }
                    });
 
                    function calculateProgress(){
                        if(scope.model.task.subTaskCount == 0){
                            if(scope.model.task.status == 'OK'){
                                scope.setProgress(scope.options[3]);
                            } else {
                                scope.setProgress(scope.customOption);
                            }
                        } else {
                            
                            var progress = Math.round(100 * scope.model.task.subTaskCompletedCount/scope.model.task.subTaskCount);
                                var progressOptions = {
                                    progress: progress,
                                    label: progress + " %",
                                    color: "0099DE"
                                };
                                scope.setProgress(progressOptions);
                        }  
                    }
              
                
                    scope.openTask = function () {
                        boardTaskService().get(taskId).then(function (task) {
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return "SubTasks";
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    };
  
                    
                    
                    scope.setProgress = function(option) {
                        scope.progress = option.progress;
                        scope.customOption.progress = option.progress;
                        scope.color = option.color;
                        if (scope.progress == 100) { // Green color if 100
                            scope.color = "#8CC152";
                        }
                    }
                }
            }
        }]);
