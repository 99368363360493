import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("deviationListFilter", ['$uibModal', '$filter', '$translate', 'deviationService', 'areaService', 'authService', 'projectService', 'debounce',
        function ($uibModal, $filter, $translate, deviationService, areaService, authService, projectService, debounce) {
            return {
                templateUrl: 'deviationListFilter.html',
                restrict: 'E',
                scope: {
                    'filterInitial': '<',
                    'onFilterChanged': '&',
                    'showHeader': "<"
                },
                link: function (scope, elem, attrs) {

                    scope.isSearchWordActive = false;
                    scope.isFilterActive = false;
                    scope.consequenceFilterActive = false;
                    scope.myResponsibleFilterActive = false;
                    scope.myRegistrationFilterActive = false;
                    scope.archivedFilterActive = false;
                    scope.assets = [];
                    scope.followupAssets = [];
                    scope.categories = [];
                    scope.consequences = [];
                    scope.causes = [];
                    scope.projects = [];
                    scope.areas = [];
                    scope.deviationtypes = [];

                    scope.severityOptions = [
                        {
                            severityDB: 1,
                            translated: $translate.instant('COMMON_LOW'),
                            cssClass: "low-severity-button",
                            filteredAndInResultList: false
                        }, {
                            severityDB: 2,
                            translated: $translate.instant('COMMON_MEDIUM'),
                            cssClass: "medium-severity-button",
                            filteredAndInResultList: false
                        }, {
                            severityDB: 3,
                            translated: $translate.instant('COMMON_HIGH'),
                            cssClass: "high-severity-button",
                            filteredAndInResultList: false
                        }, {
                            severityDB: 0,
                            translated: $translate.instant('COMMON_NOT_SPECIFIED'),
                            cssClass: "btn-default",
                            filteredAndInResultList: false
                        }
                    ];

                    scope.status = [
                        { id: 0, name: $translate.instant("COMMON_NEW"), filteredAndInResultList: false },
                        { id: 10, name: $translate.instant("DEVIATION_IN_PROGRESS"), filteredAndInResultList: false },
                        { id: 20, name: $translate.instant("DEVIATION_RESOLVED"), filteredAndInResultList: false },
                        { id: 30, name: $translate.instant("COMMON_REJECTED"), filteredAndInResultList: false },
                    ];

                    scope.$watch('filterInitial', function (filters) {
                        if (!filters) return;
                        filters.forEach(filter => {
                            switch (filter.sourceColumn) {
                                case "status":
                                    filter.items.forEach(item => {
                                        var status = $filter('filter')(scope.status, { id: parseInt(item) }, true);
                                        if (status && status.length > 0) {
                                            status[0].filteredAndInResultList = true;
                                        }
                                    });
                                    break;
                                case "severity":
                                    filter.items.forEach(item => {
                                        var severity = $filter('filter')(scope.severityOptions, { severityDB: parseInt(item) }, true);
                                        if (severity && severity.length > 0) {
                                            severity[0].filteredAndInResultList = true;
                                        }
                                    });
                                    break;
                                case "deviationTypeId":
                                    setDeviationTypeFilter();
                                    break;
                                case "assetId":
                                    setAssetFilter();
                                    break;
                                case "followupAssetId":
                                    setFollowupAssetFilter();
                                    break;
                                case "projectId":
                                    setProjectFilter();
                                    break;
                                case "areaId":
                                    setAreaFilter();
                                    break;
                                case "problemCategoryId":
                                    setCategoryFilter();
                                    break;
                                case "lossTypeId":
                                    setConsequenceFilter();
                                    break;
                                case "problemReasonId":
                                    setCauseFilter();
                                    break;
                                case "archivedDate":
                                    scope.archivedFilterActive = true;
                                    break;
                                case "ownerUserId":
                                    scope.myResponsibleFilterActive = true;
                                    break;
                                case "createdByUserId":
                                    scope.myRegistrationFilterActive = true;
                                    break;
                                case "dueDate":
                                    scope.overdueFilterActive = true;
                                    break;
                                case "statusImplementedDate":
                                    scope.overdueFilterActive = true;
                                    break;
                                default:
                                    break;
                            }
                        });
                    });

                    scope.toggleArchivedFilter = function () {
                        scope.archivedFilterActive = !scope.archivedFilterActive;
                        filterDebounce();
                    }

                    scope.toggleOverdueFilter = function () {
                        scope.overdueFilterActive = !scope.overdueFilterActive;
                        filterDebounce();
                    }

                    scope.filterPriority = function (priority) {
                        priority.filteredAndInResultList = !priority.filteredAndInResultList;
                        filterDebounce();
                    }

                    scope.clearFilter = function () {
                        scope.resetFilter();
                        if (scope.onFilterChanged) {
                            scope.onFilterChanged({ filter: scope.filter })
                        }
                    }
                    scope.resetFilter = function () {
                        scope.query = "";
                        resetStatusFilter();
                        resetAssetFilter();
                        resetFollowupAssetFilter();
                        resetAreaFilter();
                        resetProjectFilter();
                        resetCategoryFilter();
                        resetConsequenceFilter();
                        resetCauseFilter();
                        resetDeviationTypeFilter();
                        resetSeverityFilter();
                        scope.myResponsibleFilterActive = false;
                        scope.myRegistrationFilterActive = false;
                        scope.filter = [];
                    }

                    scope.toggleMyRegistrationFilter = function () {
                        scope.myRegistrationFilterActive = !scope.myRegistrationFilterActive;
                        filterDebounce();
                    }
                    scope.toggleMyResponsibleFilter = function () {
                        scope.myResponsibleFilterActive = !scope.myResponsibleFilterActive;
                        filterDebounce();
                    }
                    function loadAssets() {
                        assetService.getAssetsWithDeviation().then(function (assets) {
                            scope.assets = _.sortBy(assets, function (i) { return i.name.toLowerCase(); });
                            scope.followupAssets = JSON.parse(JSON.stringify(scope.assets));
                            setAssetFilter();
                            setFollowupAssetFilter();
                        });
                    }
                    function loadCategories() {
                        deviationService().getProblemCategoriesWithDeviation().then(function (items) {
                            scope.categories = items;
                            setCategoryFilter();
                        });
                    }
                    function loadConsequences() {
                        deviationService().getConsequences().then(function (items) {
                            scope.consequences = items;
                            setConsequenceFilter();
                        });
                    }
                    function loadCauses() {
                        deviationService().getCauses().then(function (items) {
                            scope.causes = items;
                            setCauseFilter();
                        });
                    }


                    function loadProjects() {
                        projectService().getProjectListWithDeviation().then(function (items) {
                            scope.projects = items;
                            setProjectFilter();
                        });
                    }
                    function loadAreas() {
                        areaService().getAreasWithDeviation().then(function (items) {
                            scope.areas = items;
                            setAreaFilter();
                        });
                    }

                    function loadDeviationTypes() {
                        deviationService().getDeviationTypes().then(function (types) {
                            scope.deviationtypes = types;
                            setDeviationTypeFilter();
                        });

                    }

                    function setAreaFilter() {
                        if (scope.filterInitial && scope.areas && scope.areas.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "areaId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var area = $filter('filter')(scope.areas, { id: parseInt(item) }, true);
                                    if (area && area.length > 0) {
                                        area[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }
                    function setCategoryFilter() {
                        if (scope.filterInitial && scope.categories && scope.categories.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "problemCategoryId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var category = $filter('filter')(scope.categories, { id: parseInt(item) }, true);
                                    if (category && category.length > 0) {
                                        category[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }
                    function setConsequenceFilter() {
                        if (scope.filterInitial && scope.consequences && scope.consequences.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "lossTypeId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var consequence = $filter('filter')(scope.consequences, { id: parseInt(item) }, true);
                                    if (consequence && consequence.length > 0) {
                                        consequence[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }
                    function setCauseFilter() {
                        if (scope.filterInitial && scope.causes && scope.causes.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "problemReasonId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var cause = $filter('filter')(scope.causes, { id: parseInt(item) }, true);
                                    if (cause && cause.length > 0) {
                                        cause[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }
                    function setProjectFilter() {
                        if (scope.filterInitial && scope.projects && scope.projects.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "projectId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var project = $filter('filter')(scope.projects, { id: parseInt(item) }, true);
                                    if (project && project.length > 0) {
                                        project[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }
                    function setFollowupAssetFilter() {
                        if (scope.filterInitial && scope.followupAssets && scope.followupAssets.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "followupAssetId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var asset = $filter('filter')(scope.followupAssets, { id: parseInt(item) }, true);
                                    if (asset && asset.length > 0) {
                                        asset[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }

                    function setAssetFilter() {
                        if (scope.filterInitial && scope.assets && scope.assets.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "assetId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var asset = $filter('filter')(scope.assets, { id: parseInt(item) }, true);
                                    if (asset && asset.length > 0) {
                                        asset[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }
                    function setDeviationTypeFilter() {
                        if (scope.filterInitial && scope.deviationtypes && scope.deviationtypes.length > 0) {
                            var filter = $filter('filter')(scope.filterInitial, { sourceColumn: "deviationTypeId" }, true);
                            if (filter && filter.length > 0) {
                                filter[0].items.forEach(item => {
                                    var type = $filter('filter')(scope.deviationtypes, { id: parseInt(item) }, true);
                                    if (type && type.length > 0) {
                                        type[0].filteredAndInResultList = true;
                                    }
                                });
                            }
                        }
                    }

                    function resetStatusFilter() {
                        angular.forEach(scope.status, function (status) {
                            status.filteredAndInResultList = false;
                        });
                    }
                    function resetAssetFilter() {
                        angular.forEach(scope.assets, function (asset) {
                            asset.filteredAndInResultList = false;
                        });
                    }
                    function resetFollowupAssetFilter() {
                        angular.forEach(scope.followupAssets, function (asset) {
                            asset.filteredAndInResultList = false;
                        });
                    }
                    function resetDeviationTypeFilter() {
                        angular.forEach(scope.deviationtypes, function (type) {
                            type.filteredAndInResultList = false;
                        });
                    }

                    function resetProjectFilter() {
                        angular.forEach(scope.projects, function (project) {
                            project.filteredAndInResultList = false;
                        });
                    }
                    function resetAreaFilter() {
                        angular.forEach(scope.areas, function (area) {
                            area.filteredAndInResultList = false;
                        });
                    }
                    function resetCategoryFilter() {
                        angular.forEach(scope.categories, function (category) {
                            category.filteredAndInResultList = false;
                        });
                    }
                    function resetConsequenceFilter() {
                        angular.forEach(scope.consequences, function (consequence) {
                            consequence.filteredAndInResultList = false;
                        });
                    }
                    function resetCauseFilter() {
                        angular.forEach(scope.causes, function (cause) {
                            cause.filteredAndInResultList = false;
                        });
                    }
                    function resetSeverityFilter() {
                        angular.forEach(scope.severityOptions, function (item) {
                            item.filteredAndInResultList = false;
                        });
                    }

                    function getAllData() {
                        // TODO: Should only get field with devation reqistered
                        loadDeviationTypes();
                        loadAreas();
                        loadProjects();
                        loadCategories();
                        loadConsequences();
                        loadCauses();
                        loadAssets();
                        scope.resetFilter();
                    }
                    getAllData();


                    scope.search = function () {
                        if (scope.query) {
                            scope.isSearchWordActive = true;

                        } else {
                            scope.isSearchWordActive = false;
                        }
                        filterDebounce();
                    }


                    scope.filterSeverity = function (severity) {
                        severity.filteredAndInResultList = !severity.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterDeviationType = function (type) {
                        type.filteredAndInResultList = !type.filteredAndInResultList;
                        filterDebounce();
                    }

                    scope.filterStatus = function (status) {
                        status.filteredAndInResultList = !status.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterArea = function (area) {
                        area.filteredAndInResultList = !area.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterAsset = function (asset) {
                        asset.filteredAndInResultList = !asset.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterFollowupAsset = function (asset) {
                        asset.filteredAndInResultList = !asset.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterProject = function (project) {
                        project.filteredAndInResultList = !project.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterCategory = function (category) {
                        category.filteredAndInResultList = !category.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterConsequence = function (consequence) {
                        consequence.filteredAndInResultList = !consequence.filteredAndInResultList;
                        filterDebounce();
                    }
                    scope.filterCause = function (cause) {
                        cause.filteredAndInResultList = !cause.filteredAndInResultList;
                        filterDebounce();
                    }


                    function addToFilterIfActive(filter, list, sourceColumn, property) {
                        var itemToShow = $filter('filter')(list, { filteredAndInResultList: true }, true);
                        if (itemToShow.length > 0) {

                            var itemFilter = {
                                sourceColumn: sourceColumn,
                                operator: "InList",
                                items: []
                            };
                            itemToShow.forEach(item => {
                                itemFilter.items.push(item[property])
                            });
                            filter.push(itemFilter)
                        }
                    }

                    var filterDebounce = debounce(setFilter, 500, false);
                    function setFilter() {

                        var filter = [];
                        addToFilterIfActive(filter, scope.severityOptions, "severity", "severityDB");
                        addToFilterIfActive(filter, scope.deviationtypes, "deviationTypeId", "id");
                        addToFilterIfActive(filter, scope.status, "status", "id");
                        addToFilterIfActive(filter, scope.assets, "assetId", "id");
                        addToFilterIfActive(filter, scope.followupAssets, "followupAssetId", "id");
                        addToFilterIfActive(filter, scope.projects, "projectId", "id");
                        addToFilterIfActive(filter, scope.areas, "areaId", "id");
                        addToFilterIfActive(filter, scope.categories, "problemCategoryId", "id");
                        addToFilterIfActive(filter, scope.consequences, "lossTypeId", "id");
                        addToFilterIfActive(filter, scope.causes, "problemReasonId", "id");
                        if (scope.myResponsibleFilterActive) {
                            var userid = authService.getCurrentUser().userId;
                            var itemFilter = {
                                sourceColumn: "ownerUserId",
                                operator: "InList",
                                items: [userid]
                            };
                            filter.push(itemFilter);
                        }
                        if (scope.myRegistrationFilterActive) {
                            let userid = authService.getCurrentUser().userId;
                            let itemFilter = {
                                sourceColumn: "createdByUserId",
                                operator: "InList",
                                items: [userid]
                            };
                            filter.push(itemFilter);
                        }
                        if (scope.archivedFilterActive) {
                            let archivedDate = {
                                sourceColumn: "archivedDate",
                                operator: "IsNotNull",
                                items: []
                            };
                            filter.push(archivedDate);
                        }
                        if (scope.overdueFilterActive) {
                            let itemFilter = {
                                sourceColumn: "dueDate",
                                operator: "LessThanDate",
                                items: [moment().subtract(1, 'days')]
                            };
                            let notImplementedFilter = {
                                sourceColumn: "statusImplementedDate",
                                operator: "IsNull",
                                items: []
                            };
                            filter.push(itemFilter);
                            filter.push(notImplementedFilter);
                        }
                        if (scope.isSearchWordActive && scope.query) {
                            // CHeck for ID filter
                            if (isNumeric(scope.query)) {
                                // Check if suggestion is ID and show.
                                deviationService().get(scope.query).then(function (deviation) {
                                    var modalInstance = $uibModal.open({
                                        backdrop: 'static',
                                        templateUrl: 'fullDeviation.html',
                                        controller: 'fullDeviationController',
                                        windowClass: 'full-screen',
                                        resolve: {
                                            deviation: function () {
                                                return deviation;
                                            },
                                            activeTab: function () {
                                                return "Info";
                                            }
                                        }
                                    });
                                    modalInstance.result.then(function () {

                                    });
                                });
                            }
                            var itemFilter = {
                                sourceColumn: "title",
                                operator: "Contains",
                                items: [scope.query]
                            };


                            filter.push(itemFilter);
                        }
                        if (scope.onFilterChanged) {
                            scope.onFilterChanged({ filter })
                        }

                    }

                    function isNumeric(num) {
                        return !isNaN(num)
                    }
                }
            }
        }]);