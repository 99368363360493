<script setup lang="ts">
    import { PropType } from "vue"
    import { Job, JobPart } from "@api"
    import { html } from "@common/services/template/templateLiteral"
    import dialog from "@common/components/digilean/DigileanDialog"
    import { translateLabel } from "@common/services/language/DigiLeanLang"

    const props = defineProps({
        job: {
            type: Object as PropType<Job>,
        }
    })

    type Emits = {
        (e: "update", jobpart: JobPart): void
    }
    const emit = defineEmits<Emits>()
    
    let openConfigEdit = async function(jobPart: JobPart) {
        try {
            const template = html`<digilean-json-editor data-ref="config"></digilean-json-editor>`
            const res = await dialog.openForm({
                title: "COMMON_CONFIGURATION"
            }, template, jobPart)

            jobPart.config = res.config
            
            emit("update", jobPart)
        }
        catch (err) {
            if (err != "cancel")
                console.error(err)
        }
    }

</script>
<style>
</style>
<template>

    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col"><translate-label label="COMMON_ENABLED"></translate-label></th>
                <th scope="col"><translate-label label="COMMON_NAME"></translate-label></th>
                <th scope="col"><translate-label label="COMMON_SOURCE"></translate-label></th>
                <th scope="col">Job Part Type</th>
                <th scope="col"><translate-label label="COMMON_CONFIGURATION"></translate-label></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(jobPart, index) in job?.jobParts!">
                <td>{{index}}</td>
                <td>
                    <input type="checkbox" v-model="jobPart.enabled" />
                </td>
                <td>{{jobPart.name}}</td>
                <td>{{jobPart.source}}</td>
                <td>{{jobPart.jobPartType}}</td>
                <td>
                    <span>{{jobPart.config}}</span>
                    <digilean-button @click="() => openConfigEdit(jobPart)">edit</digilean-button>
                </td>
            </tr>
        </tbody>
    </table>

</template>