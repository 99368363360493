﻿import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as translationService from "@common/services/translationService"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive("projectBoard", ['$filter', '$state', '$translate', 'projectService', '$uibModal', 'excelService','navigationService', 'modalService', 'orderByFilter', 'taskDrawingService', 'isTeamsMode',
        function ($filter, $state, $translate, projectService, $uibModal, excelService, navigationService, modalService, orderByFilter, taskDrawingService,isTeamsMode) {
            return {
                templateUrl: 'projectboard.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'isAdminMode': '=',
                    'options': '<?'
                },
                link: function ($scope, elem, attrs) {
                    $scope.editable = false;
                    $scope.projects = [];
                    $scope.canEdit = false;
                    $scope.showtitle = true;
                    $scope.query = "";
                    $scope.viewMode = "List";

                    $scope.showFileGenerators = true;
                    if (isTeamsMode) {
                        $scope.showFileGenerators = false;
                    }

                    $scope.isStar = false;
                    $scope.quickAccess = [];

                    $scope.settingsSortColumn = null;
                    $scope.sortColumnIndex = null;
                    $scope.isAscedning = true;
                    $scope.sortColumn = null;
                    $scope.orderBy = function (column) {
                        if ($scope.sortColumn && $scope.sortColumn != column) {
                            $scope.sortColumn.sort = "none";
                        }
                        if ($scope.settingsSortColumn && $scope.settingsSortColumn.id && $scope.settingsSortColumn.id != column.id) {
                            var foundColumn = $filter('filter')($scope.projectColumns, { id: $scope.settingsSortColumn.id }, true);
                            if (foundColumn.length > 0) {
                                foundColumn[0].sort = "none"; //reset saved sortorder, but not changing settings 
                            }
                        }
                        if (!column.sort) column.sort = "none";
                        column.sort = getNextSortOrder(column.sort);
                        $scope.sortColumn = column;
                        $scope.sortColumnIndex = $scope.projectColumns.indexOf(column);
                        if (column.sort == "none") {
                            $scope.projects = orderByFilter($scope.projects, 'sortOrder');
                        }
                        if (column.sort == "asc") {
                            $scope.projects = orderByFilter($scope.projects, getSortValue, false);
                        }
                        if (column.sort == "desc") {
                            $scope.projects = orderByFilter($scope.projects, getSortValue, true);
                        }
                        if($scope.isAdminMode) {
                            if(column.sort == "desc" || column.sort == "asc"){
                                $scope.settingsSortColumn = {
                                    id: column.id,
                                    attributeType: column.attributeType,
                                    sort: column.sort,
                                };
                            } else {
                                $scope.settingsSortColumn = null;
                            }
                            updateSettings();
                        }
                    }

                    var getNextSortOrder = function (currentSortOrder) {
                        if (currentSortOrder == "none") return "asc";
                        if (currentSortOrder == "asc") return "desc";
                        if (currentSortOrder == "desc") return "none";
                    }

                    var getSortValue = function (project) {
                        if (project.cellModels) {
                            var cell = project.cellModels[$scope.sortColumnIndex];
                            return cell.getSortValue();
                        }
                        return project.name;

                    }

                    function setSettings(){
                        if ($scope.board.settings) {
                            $scope.settings = JSON.parse($scope.board.settings);
                            if ($scope.settings.settingsSortColumn) {
                                $scope.settingsSortColumn = $scope.settings.settingsSortColumn;
                            }
                        }    
                    }
                    
                    // load users
                    var profileImageUsers = [];
                    function getAllUsers() {
                        profileService.getUsersWithProfileImage().then(function (imageUsers) {
                            profileImageUsers = imageUsers;
                            customerService.getAllUsers().then(function (users) {

                                for (var i = 0; i < users.length; i++) {
                                    var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(users[i].userId, "ExtraSmall", profileImageUsers);
                                    if (imageUrl) {
                                        users[i].profileImage = true;
                                        users[i].profileImageUrl = imageUrl;
                                    } else {
                                        users[i].profileImage = false;
                                    }
                                }
                                $scope.users = users;
                            });
                        });
                    }
                    getAllUsers();

                    $scope.$watch('query', function () {
                        $scope.searchQuery = angular.copy($scope.query);
                    });

                    $scope.$watch("isAdminMode", function () {

                    });
                    $scope.$watch("options", function (options) {
                        setHeightBasedOnOptions($scope.options);
                    });

                    $scope.toggleViewMode = function (viewMode) {
                        $scope.viewMode = viewMode;
                    }

                    $scope.exportToExcel = function () {
                        excelService().exportProjectBoard($scope.board.id);
                    }

                    function setHeightBasedOnOptions(options) {
                        if (!options) {
                            setHeight();
                            return;
                        }
                        if (options.targetHeight) {
                            setHeight(options.targetHeight);
                        } else {
                            setHeight();
                        }
                    }

                    function setHeight(targetHeight) {
                        if (!targetHeight) {
                            var boardContainer = $(elem).find(".project-board-container");

                            var offset = boardContainer.offset();
                            var windowHeight = $(window).height();
                            var boardTargetHeight = windowHeight - offset.top - 20;
                            if (boardTargetHeight < 500) boardTargetHeight = 500;
                            boardContainer.css("height", boardTargetHeight);
                        } else {
                            var optionsHeight = 80;
                            var boardTableHeight = targetHeight - optionsHeight
                            $(elem).find(".project-board-container").css("height", boardTableHeight);
                        }


                    }
                    $scope.search = function (query) {
                        //$scope.searchPerformed = false;
                        $scope.searchQuery = angular.copy(query);
                        // $scope.searchResult = true;
                    }

                    $scope.showMoreColumns = function () {

                        var options = {
                            context: "project"
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'projectCellSelection.html',
                            controller: 'projectCellSelectionController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options:options
                            }
                        });
                        modalInstance.result.then(function (result) {
                            if (result) {
                                $scope.addColumn(result); //addColumn('textfromapp')
                            }
                        });
                    };

                    var updatedEditable = function () {
                        // if ($scope.canEdit && $scope.isAdminMode) {
                        //     $scope.editable = true;
                        // } else {
                        //     $scope.editable = false;
                        // }
                        if ($scope.isAdminMode) {
                            $scope.editable = true;
                        } else {
                            $scope.editable = false;
                        }
                        $scope.editable = true;
                    }

                    // Drag and drop of rows events
                    $scope.$on('row-header-bag.drop-model', function (e, el) {
                        if($scope.canEdit){
                            var rowOrder = $scope.projects;
                            var sortOrder = [];
                            for (var i = 0; i < $scope.projects.length; i++) {
                                var row = $scope.projects[i];
                                var rowOrder = {
                                    rowId: row.id,
                                    sortOrder: i
                                }
                                sortOrder.push(rowOrder)
                            }
                            projectService().updateProjectBoardRowSortOrder($scope.board.id, sortOrder).then(function () {

                            });
                        }

                    });
                    // Drag and drop of columns events
                    $scope.$on('column-header-bag.drop-model', function (e, el) {
                        if($scope.canEdit){
                            var columnOrder = $scope.projectColumns;
                            var sortOrder = [];
                            for (var i = 0; i < $scope.projectColumns.length; i++) {
                                var column = $scope.projectColumns[i];
                                var columnOrder = {
                                    boardAttributeId: column.id,
                                    sortOrder: i
                                }
                                sortOrder.push(columnOrder)
                            }
                            projectService().updateProjectBoardSortOrder($scope.board.id, sortOrder).then(function () {

                            });
                        }
                    });
                    $scope.$on('column-header-bag.drag', function (e, el) {
                        $(el).find(".drag-drop-icon").hide();
                        $(el).find(".option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    $scope.$on('column-header-bag.drop', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                        el.removeClass('drag-moved');
                    });

                    $scope.$on('column-header-bag.dragend', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                    });

                    $scope.$on('column-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    $scope.$on('column-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });

                    $scope.$watch("model", function () {
                        if ($scope.model == null) return;
                        $scope.board = $scope.model.board;

                        boardService.canEdit($scope.board.id).then(function (canEdit) {
                            $scope.canEdit = canEdit;
                            $scope.editable = canEdit;
                            
                        });

                        navigationService().isBoardAdmin($scope.board.id).then(function (isAdmin) {
                            $scope.hasBoardDesignerPrivileges = isAdmin;
                        });

                        $scope.projectColumns = $scope.model.attributes;
                        $scope.projects = $scope.model.projects;
                        $scope.title = $scope.board.name;
                        if ($scope.projects) {
                            for (var i = 0; i < $scope.projects.length; i++) {
                                var project = $scope.projects[i];
                                project.cellModels = [];
                                calculateProjectAttributes(project, $scope.projectColumns)
                            }
                            setSettings();
                            setTimeout(function () {
                                setColumnSorting();
                            }, 500);
                            
                        }
                        checkFavorite();
                    });

                    $scope.subscribe("BoardNameUpdated", function (boardname) {
                        if (boardname.boardId === $scope.board.id) {
                            $scope.title = boardname.name;
                        }
                    });



                    $scope.subscribe("ProjectBoardAttributeUpdated", function (boardattribute) {
                        if (boardattribute.boardId === $scope.board.id) {
                            // $scope.projectColumns
                            var hasColumn = $filter('filter')($scope.projectColumns, { id: boardattribute.id }, true);
                            if (hasColumn.length > 0) {
                                var column = hasColumn[0];
                                if (column.name !== boardattribute.name) {
                                    column.name = boardattribute.name;
                                }
                                if (boardattribute.settings) {
                                    column.settings = JSON.parse(boardattribute.settings);
                                }
                                //TODO: antar vi kan legge til endring i kolonnerekkefølgen også på dette tidspunktet
                            }
                        }
                    });


                    $scope.subscribe("ProjectAddedToBoard", function (projectBoardItem) {
                        if (projectBoardItem.boardId === $scope.board.id) {
                            var isPartOfBoard = $filter('filter')($scope.projects, { projectBoardId: projectBoardItem.id });
                            if (isPartOfBoard === undefined || isPartOfBoard.length === 0) {
                                var project = {
                                    project: projectBoardItem.project,
                                    attributes: [],
                                    projectBoardId: projectBoardItem.id
                                }
                                project.cellModels = [];
                                calculateProjectAttributes(project, $scope.projectColumns);
                                $scope.projects.push(project);
                            }
                        }
                    });
                    $scope.subscribe("ProjectRemovedFromBoard", function (projectBoardItem) {
                        var isPartOfBoard = $filter('filter')($scope.projects, { projectBoardId: projectBoardItem.id });
                        if (isPartOfBoard && isPartOfBoard.length === 1) {
                            var projectItem = isPartOfBoard[0];
                            projectItem.cssState = "animated slideOutRight"; // Add animation
                            var index = $scope.projects.indexOf(projectItem);
                            if (index > -1) {
                                $scope.projects.splice(index, 1);
                            }

                        }
                    });
                    $scope.subscribe("ProjectBoardSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === $scope.board.id) {
                            boardService.get(payLoad.boardId).then(function (model) {
                                $scope.model = model;
                            });
                        }
                    });

                    $scope.subscribe("ProjectBoardRowSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === $scope.tableId) {
                            boardService.get(payLoad.boardId).then(function (model) {
                                $scope.model = model;
                            });
                        }
                    });
                    $scope.subscribe("ProjectDeleted", function (payLoad) {
                        var foundProject = null;
                        for (var p = 0; p < $scope.projects.length; p++) {
                            if ($scope.projects[p].id === payLoad.id) {
                                foundProject = $scope.projects[p];
                                foundProject.cssState = "animated slideOutRight";
                                $scope.projects.splice(p, 1);
                                break;
                            }
                        }
                    });


                    var calculateProjectAttributes = function (project, boardAttributes) {
                        project.id = project.project.id;
                        for (let index = 0; index < boardAttributes.length; index++) {
                            let attribute = boardAttributes[index];
                            var cellModel = {
                                project: project.project,
                                column: attribute,
                                boardAttributeId: attribute.id,
                                projectAttribute: null
                            };
                            var hasAttribute = $filter('filter')(project.attributes, { projectBoardAttributeId: attribute.id }, true);
                            if (hasAttribute.length > 0) {
                                attribute = hasAttribute[0];
                                if (attribute.value) {
                                    attribute.value = JSON.parse(attribute.value);
                                }
                                cellModel.projectAttribute = attribute;
                            } else {
                                // Create default attribute
                                cellModel.projectAttribute = {
                                    id: 0,
                                    projectId: project.project.id,
                                    projectBoardAttributeId: attribute.id,
                                    value: null
                                }
                            }
                            if (cellModel.column.settings && typeof (cellModel.column.settings) === "string") {
                                cellModel.column.settings = JSON.parse(cellModel.column.settings);
                            }
                            // calculateColumnSum(boardAttributes[index]);
                            project.cellModels.push(cellModel)
                        }
                    }

                    var addAttributeToProjects = function (attribute) {
                        for (let index = 0; index < $scope.projects.length; index++) {
                            var project = $scope.projects[index];
                            var cellModel = {
                                project: project.project,
                                column: attribute,
                                boardAttributeId: attribute.id,
                                projectAttribute: null
                            }
                            // Create default attribute
                            cellModel.projectAttribute = {
                                id: 0,
                                projectId: project.project.id,
                                projectBoardAttributeId: attribute.id,
                                value: null
                            }
                            project.cellModels.push(cellModel)
                        }
                    }

                    function setColumnSorting(){
                        if(!$scope.projects) return;
                        if($scope.settingsSortColumn){
                            var foundColumn = $filter('filter')($scope.projectColumns, { id: $scope.settingsSortColumn.id }, true);
                            if (foundColumn.length > 0) {
                                $scope.sortColumnIndex = $scope.projectColumns.indexOf(foundColumn[0]);
                                foundColumn[0].sort = $scope.settingsSortColumn.sort;
                                if (foundColumn[0].sort == "asc") {
                                    $scope.projects = orderByFilter($scope.projects, getSortValue, false);
                                }
                                if (foundColumn[0].sort == "desc") {
                                    $scope.projects = orderByFilter($scope.projects, getSortValue, true);
                                }
                            }
                        }
                    }

                    $scope.toggleAdminMode = function () {
                        $scope.isAdminMode = !$scope.isAdminMode;
                    }
                    $scope.columnNameUpdated = function (column) {
                        projectService().updateProjectBoardAttribute(column).then(function (attribute) {

                        })
                    }
                    $scope.titleUpdated = function (title) {
                        var boardName = {
                            boardId: $scope.board.id,
                            name: title
                        }
                        boardService.updateName($scope.board.id, boardName).then(function (result) {
                        });
                    }


                    $scope.addColumn = function (type) {
                        if (type === "numberAutomatic") {
                            addNumberAutomaticColumn(type);
                        } else if (type === "textfromapp") {
                            addTextFromAppColumn(type);
                        } else if (type === "targetfromapp") {
                            addTargetFromAppColumn(type);
                        } else {
                            var headertext = translationService.getTranslatedSmartColumnName(type);
                            if (headertext === "") {
                                headertext = type.charAt(0).toUpperCase() + type.slice(1);
                            }

                            var attribute = {
                                boardId: $scope.board.id,
                                name: headertext,
                                attributeType: type,
                                sortOrder: $scope.projectColumns.length - 1
                            }
                            projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {

                            })

                        }
                    }/*
                    $scope.periodChangedHandler = function (timePeriod) {
                        if (!timePeriod.timeframe) return;
                        if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                        scope.timePeriod = timePeriod;
                        scope.options.timePeriod = timePeriod;
                        loadData();
                        col.settings.timePeriod = timePeriod; //result = timePeriod.timeframe e.g."all"
                                var payload = {
                                    boardAttributeId: col.id,
                                    timePeriod: col.settings.timePeriod
                                }
                                $scope.publish("ProjectNumberCellAutomaticTimeframeUpdated", payload);
        
                                projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                                })
                    }*/
                    $scope.editTimeframe = function (col) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'timeframeSelectorModal.html',
                            controller: 'timeframeSelectorModal',
                            resolve: {
                                timePeriod: function () {
                                    return col.settings.timePeriod;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            col.settings.timePeriod = result; //result = timePeriod.timeframe e.g."all"
                            
                            var payload = {
                                boardAttributeId: col.id,
                                timePeriod: col.settings.timePeriod
                            }
                            $scope.publish("ProjectNumberCellAutomaticTimeframeUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })

                        });

                    }
                    $scope.getTimeframeLabel = function (timeframe) {
                        return timeService.getTimeframeLabel(timeframe);
                    }
                    $scope.predefinedIcons = function (col) {
                        if (!col.settings) {
                            col.settings = {};
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'predefinedIcons.html',
                            controller: 'predefinedIcons',
                            resolve: {
                                labels: function () {
                                    return col.settings.predefinedIcons;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            if (!col.settings) {
                                col.settings = {};
                            }
                            col.settings.predefinedIcons = result;
                            var payload = {
                                type: 'project',
                                boardAttributeId: col.id,
                                columnId: col.id,
                                predefinedIcons: col.settings.predefinedIcons
                            }
                            $scope.publish("SmartIconCellSelectorUpdated", payload);
                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })

                        });
                    }
                    $scope.predefinedLabels = function (col) {
                        if (col.settings.usePredefinedLabels) {
                            var dataSource = null;
                            var filters = null;
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'predefinedLabels.html',
                                controller: 'predefinedLabels',
                                resolve: {
                                    labels: function () {
                                        return col.settings.predefinedLabels;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                col.settings.predefinedLabels = result;
                                var payload = {
                                    boardAttributeId: col.id,
                                    predefinedLabels: col.settings.predefinedLabels,
                                    usePredefinedLabels: col.settings.usePredefinedLabels
                                }
                                $scope.publish("ProjectTextCellLabelUpdated", payload);

                                projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                                })

                            });
                        } else {
                            col.settings.usePredefinedLabels = false;
                            var payload = {
                                projectAttributeId: col.id,
                                predefinedLabels: col.settings.predefinedLabels,
                                usePredefinedLabels: col.settings.usePredefinedLabels
                            }
                            $scope.publish("ProjectTextCellLabelUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        }
                    }

                    
                    $scope.changeDateColor = function (col) {

                        if (col.settings.changeColor) {
                            if(!col.settings.changeColorXDaysBefore){
                                col.settings.changeColorXDaysBefore = 1;
                            }
                            var payload = {
                                projectAttributeId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            $scope.publish("ProjectDateColorCellUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        } else {
                            col.settings.changeColor = false;
                            var payload = {
                                projectAttributeId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            $scope.publish("ProjectDateColorCellUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        }
                    }

                    $scope.updateChangeColorXDaysBefore = function (col) {
                            col.settings.changeColor = true;
                            var payload = {
                                projectAttributeId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            $scope.publish("ProjectDateColorCellUpdated", payload);

                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                            })
                        
                    }

                    
                    $scope.columnWidthChangeHandler = function(col){
                        if(col){
                            projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                            })
                        }
                    }

                    function addTextFromAppColumn(type) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'cellCloneSelectorForm.html',
                            controller: 'cellCloneSelectorController',
                            resolve: {
                                celltype: function () {
                                    return type
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            var test = result;
                            if (result) {
                                /*var cellSettings = JSON.stringify({
                                    visualizationType: result.name, 
                                    cloneId: result.id
                                });*/
                                var cellSettings = JSON.stringify({
                                    visualizationType: result
                                })
                                var attribute = {
                                    boardId: $scope.board.id,
                                    name: result,
                                    attributeType: type,
                                    sortOrder: $scope.projectColumns.length - 1,
                                    settings: cellSettings,

                                }
                                projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {
                                });
                            }

                        }, function () {

                        });
                    };
                    function addTargetFromAppColumn(type) {

                        //var visualizationType = null;
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'targetVisualizationSelectorForm.html',
                            controller: 'targetVisualizationSelectorController'
                        });

                        modalInstance.result.then(function (result) {

                            var cellSettings = JSON.stringify({
                                visualizationType: result
                            })
                            var attribute = {
                                boardId: $scope.board.id,
                                name: result,
                                attributeType: type,
                                sortOrder: $scope.projectColumns.length - 1,
                                settings: cellSettings,

                            }
                            projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {
                            });

                        }, function () {

                        });
                    };
                    function addNumberAutomaticColumn(type) {

                        var dataSource = null;
                        var filters = null;
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return false;
                                },
                                dataSource: function () {
                                    return dataSource;
                                },
                                filters: function () {
                                    return filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return "PROJECT";
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                            var timePeriod = {
                                timeframe: "all",
                                period: timeService.getTimePeriod("all")
                            }

                            var unit = null;
                            if (result.dataSource.valueElement) {
                                unit = result.dataSource.valueElement.unit;
                            }


                            var cellSettings = JSON.stringify({
                                dataSource: result.dataSource,
                                timePeriod: timePeriod,
                                filters: result.filters,
                                aggregateType: "SUM",
                                unit: unit,
                                unitPlacement: "right"
                            })
                            var attribute = {
                                boardId: $scope.board.id,
                                name: result.dataSource.title,
                                attributeType: type,
                                sortOrder: $scope.projectColumns.length - 1,
                                settings: cellSettings,

                            }
                            projectService().addProjectAttributeToBoard(attribute).then(function (savedAttribute) {
                            })

                        });
                    };

                    $scope.deleteColumn = function (column) {
                        projectService().deleteProjectAttribute(column);
                    }

                    $scope.updateAggregateType = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            aggregateType: col.settings.aggregateType
                        }
                        $scope.publish("ProjectNumberCellAggregateTypeUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                        })
                    };
                    $scope.updateUnit = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            unit: col.settings.unit
                        }
                        $scope.publish("ProjectNumberCellUnitUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                        })
                    };
                    $scope.updateUnitPlacement = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            unitPlacement: col.settings.unitPlacement
                        }
                        $scope.publish("ProjectNumberCellUnitPlacementUpdated", payload);

                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {
                        })
                    };
                    $scope.updateDecimals = function (col) {
                        var payload = {
                            projectAttributeId: col.id,
                            decimals: col.settings.decimals
                        }
                        $scope.publish("ProjectNumberCellDecimalUpdated", payload);


                        projectService().updateProjectBoardAttribute(col).then(function (attribute) {

                        })

                    };



                    $scope.subscribe("ProjectBoardAttributeAdded", function (attribute) {
                        if (attribute.boardId === $scope.board.id) {
                            var hasColumn = $filter('filter')($scope.projectColumns, { id: attribute.id }, true);
                            if (hasColumn.length === 0) {
                                if (attribute.settings) {
                                    attribute.settings = JSON.parse(attribute.settings);
                                }
                                addAttributeToProjects(attribute);
                                $scope.projectColumns.push(attribute);
                            }
                        }
                    });
                    $scope.subscribe("ProjectBoardAttributeDeleted", function (attribute) {
                        /*var hasColumn = $filter('filter')($scope.projectColumns, { id: attribute.id }, true);
                        if (hasColumn.length > 0) {
                            var column = hasColumn[0];
                            var index = $scope.projectColumns.indexOf(column);
                            $scope.projectColumns.splice(index, 1);
                        }*/
                        if (attribute.boardId === $scope.board.id) {
                            boardService.get(attribute.boardId).then(function (model) {
                                $scope.model = model;
                            });
                        }
                    });

                    $scope.projectAttributeChangeHandler = function (projectAttribute) {
                        if (projectAttribute.value) {
                            projectAttribute.value = JSON.stringify(projectAttribute.value);
                        }

                        projectService().updateProjectAttribute(projectAttribute).then(function (savedAttribute) {
                            // Parse value again
                            projectAttribute.id = savedAttribute.id;
                            if (savedAttribute.value) {
                                projectAttribute.value = JSON.parse(savedAttribute.value);
                            }
                        });
                    }

                    $scope.addExisting = function (projecttype) {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'projectSelectorModal.html',
                            controller: 'projectSelectorController',
                            resolve: {
                                existing: function () {
                                    return $scope.projects;
                                }
                            }
                        });

                        modalInstance.result.then(function (exisitingProject) {
                            if (exisitingProject) {
                                var lastInList = getHighestSortOrder() + 1;
                                projectService().addProjectToBoard(exisitingProject.id, $scope.model.board.id, lastInList).then(function (projectboard) {
                                });
                            }
                        }, function () {
                        });


                    }
                    $scope.addProject = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'projectNewModal.html',
                            controller: 'projectNewController',
                            resolve: {
                                existing: function () {
                                    return $scope.projects;
                                }
                            }
                        });

                        modalInstance.result.then(function (newproject) {

                            if (newproject) {

                                projectService().addProject(newproject).then(function (createdProject) {
                                    var lastInList = getHighestSortOrder() + 1;
                                    projectService().addProjectToBoard(createdProject.id, $scope.model.board.id, lastInList).then(function (projectboard) {

                                    });
                                });
                            }
                        }, function () {
                        });
                    }




                    $scope.addSmarttableRow = function () {
                        var newItem = {
                            name: $translate.instant('COMMON_NEW'),
                            category: "SmartTable"
                        };

                        projectService().addProject(newItem).then(function (createdProject) {
                            var lastInList = getHighestSortOrder();
                            projectService().addProjectToBoard(createdProject.id, $scope.model.board.id, lastInList).then(function (projectboard) {

                            });
                        });
                    }

                    function getHighestSortOrder(){
                        var highestSortOrder = 1;
                        for(var i=0; i<$scope.projects.length; i++){
                            if($scope.projects[i].sortOrder >= highestSortOrder){
                                highestSortOrder = $scope.projects[i].sortOrder + 1;
                            }
                        }

                        return highestSortOrder;
                    }

                    $scope.deleteBoard = function () {
                        if (!$scope.hasBoardDesignerPrivileges) return;
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.ADMIN_DELETED_BOARD,
                                headerText: translations.COMMON_DELETE + ' ' + $scope.title + '?',
                                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
                            };

                            modalService.showModal({}, modalOptions).then(function (result) {
                                boardService.deleteBoard($scope.model.board.id).then(function () {
                                    $state.go('myspace');
                                    $scope.publish("SendBoardDeleted", $scope.model.board.id);
                                });
                            });

                        });
                    }

                    $scope.delete = function (projectinfo) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'projectDeleteModal.html',
                            controller: 'projectDeleteController',
                            resolve: {
                                board: $scope.model.board,
                                projectInfo: projectinfo

                            }
                        });

                        modalInstance.result.then(function (result) {
                        }, function () {
                        });
                    }


                    $scope.calculateNumberCellTotal = function (colDef) {
                        var total = 0;
                        for (var i = 0; i < colDef.projectAttributes.length; i++) {
                            /*var cellNumber = $filter('filter')(project.attributes, { projectBoardAttributeId: attribute.id }, true);
                            if (hasAttribute.length > 0) {
                                var attribute = hasAttribute[0];
                                if (attribute.value) {
                                    attribute.value = JSON.parse(attribute.value);
                                }
                                cellModel.projectAttribute = attribute;
                            }*/
                            var value = colDef.projectAttributes[i].value;
                            var valueObject = JSON.parse(value);
                            var cellNumber = valueObject.number;
                            if (cellNumber) {
                                total = total + cellNumber;
                            }
                        }
                        return total;
                    }

                    $scope.addToFavorites = function () {
                        $scope.isStar = true;
                        var board = {
                            id: $scope.board.id,
                            name: $scope.title
                        };
                        $scope.quickAccess.push(board);
                        saveQuickAccess();
                    }
                    $scope.removeFromFavorites = function () {
                        $scope.isStar = false;
                        var isFavorite = $filter('filter')($scope.quickAccess, { id: $scope.board.id })
                        if (isFavorite.length > 0) {
                            var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                            $scope.quickAccess.splice(currentIndex, 1);
                            saveQuickAccess();
                        }
                    }

                    function updateSettings() {
                        if ($scope.isAdminMode) {
                            var settings = {
                                boardId: $scope.board.id,
                                settingsSortColumn: $scope.settingsSortColumn
                            };
                            // Save as board settings
                            boardService.updateSettings($scope.board.id, settings);
                        }
                    }
                    function saveQuickAccess() {
                        employeeService.updateUserPreference("ProjectPortfolioFavorites", $scope.quickAccess).then(function (settings) {
                            $scope.publish("ProjectPortfolioFavoritesChanged", $scope.quickAccess);
                        });
                    }

                    function checkFavorite(){
                        employeeService.getUserPreference("ProjectPortfolioFavorites").then(function (settings) {
                            if (settings && settings.value) {
                                angular.forEach(settings.value, function (board) {
                                    if (board) {
                                        if(board.id == $scope.board.id){
                                            $scope.isStar = true;
                                        }
                                        $scope.quickAccess.push(board);
                                    }
                                });
                            }
                        });
                    }

                }
            }
        }]);