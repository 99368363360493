import { CustomerSettingKeys } from "@api"
import { getCompanySettingServer, updateCompanySettingServer} from "@common/services/customers/customerService"

type CompanySettings = { 
    [k in CustomerSettingKeys]?: string | null
}

const companySettingsCache: CompanySettings = {
    "dateFormat": null,
    "currency": null,
    "language": null
}

/**
 * Gets cached company setting as string
 * 
 * @param {CompanySettingsString} keyStr - the company settings key
 */
export async function getCompanySetting (keystr: `${CustomerSettingKeys}`) {
    const key = keystr as CustomerSettingKeys

    const value = companySettingsCache[key]
    // check specifically for not null/undefined. Empty string is valid since not all settings are set for a customer
    if (value !== null && value !== undefined) {
        const value = companySettingsCache[key] as string
        console.log(`CompanySettingsStore::return cached setting ${key}='${value}'`)
        return value
    }
    
    console.log(`CompanySettingsStore:: Setting ${key} was not cached. Get from server`)
    let valueFromServer = ""
    const setting = await getCompanySettingServer(key)
    if (setting && setting.value) {
        valueFromServer = setting.value
    }
    
    console.log(`CompanySettingsStore:: Caching ${key}='${valueFromServer}' from server`)
    updateSettingInCache(key, valueFromServer)
    return valueFromServer
}

export async function saveCompanySetting(key: CustomerSettingKeys, value: string) {
    await updateCompanySettingServer(key, value)
    console.log(`CompanySettingsStore:: Caching ${key}='${value}' because the setting was changed and saved`)
    updateSettingInCache(key, value)
}

function updateSettingInCache(key: CustomerSettingKeys, value: string | null | undefined) {
    companySettingsCache[key] = value
}
