import * as dashboardService from "@common/services/dashboardService"
import { fixTransparentText } from "@common/services/helperLib"
import { isObjectEqual } from "@common/services/helperLib"

angular
    .module('DigiLean')
    .directive("widget", ['$sce', 'debounce',
        function ($sce, debounce) {
            return {
                templateUrl: 'widget.html',
                restrict: 'E',
                scope: {
                    'widgetid': '<',
                    'header': '<',
                    'component': '<',
                    'assetId': '<',
                    'isAdminMode': '<',
                    'model': '<',
                    'settings': '<',
                    'userAccess': '<',
                    'globalTimePeriod': '<',
                    'layoutFactor': '<',
                    'isDebugMode': '<'
                },
                link: function (scope, elem, attrs) {

                    fixTransparentText(scope.settings)
                    scope.componentSettings = scope.settings; // initial settings, don't watch for changes. Changes will come via events
                    scope.isWidgetReadyToLoad = true;

                    let previouslySavedSettings = structuredClone(scope.settings)
                    console.log(`WidgetController: Id: ${scope.widgetid} Component: '${scope.component}' Initialize`)
                    // this event fires for all inluding current user
                    scope.subscribe("DashboardWidgetSettingsChanged", function (componentSettings) {
                        // console.log(`WidgetController: Id: ${scope.widgetid} Component: '${scope.component}' DashboardWidgetSettingsChanged event`, componentSettings)
                        if (componentSettings.id == parseInt(scope.widgetid)) {
                            const updatedSettings = JSON.parse(componentSettings.settings);
                            if(!isObjectEqual(scope.componentSettings, updatedSettings)){
                                scope.componentSettings = updatedSettings;
                                previouslySavedSettings = structuredClone(updatedSettings)
                            }
                        }
                    })

                    function settingsChangeHandler(settings) {
                        if (scope.widgetid == 0) return; // the widget is not yet saved to the backend
                        // check if settings did not really change
                        if (isObjectEqual(previouslySavedSettings, settings)) {
                            console.log(`WidgetController: Id: ${scope.widgetid} settingsChangeHandler: settings object was equal`)
                            return
                        }
                        const componentSettings = {
                            id: scope.widgetid,
                            settings: JSON.stringify(settings)
                        }
                        dashboardService.saveComponentSettings(scope.widgetid, componentSettings).then(() => {
                                console.log(`WidgetController: Id: ${scope.widgetid} Component: '${scope.component}' settings saved to backend`)
                            }, 
                            (err) => console.log(`WidgetController: Id: ${scope.widgetid} Component: '${scope.component}' error saving component settings `, err)
                        )
                    }
                    scope.settingsChangeHandler = settingsChangeHandler

                    // used by non-angular components
                    scope.onSettingsChangedEvent = function (e) {
                        const orgEvt = e.originalEvent
                        const settings = orgEvt.detail.settings
                        settingsChangeHandler(settings)
                    }
                },
                controller: ['$scope','$element', function ($scope, $element) {
                    $scope.disableDebugMode = function () {
                        $scope.isDebugMode = false;
                    }

                    function broadcastResize() {
                        $scope.$broadcast("widget-resized", { widgetid: $scope.widgetid })
                    }
                    const broadcastResizeDebounced = debounce(broadcastResize, 100, false)

                    let resizeObserver = new ResizeObserver((entries) => {
                        for (const entry of entries) {
                            if (entry.target.nodeName == "DIV" && entry.contentBoxSize) {
                                // const wrapper = entry.target
                                // const contentBoxSize = wrapper.getBoundingClientRect()
                                // console.log(`WidgetController:Resize observed. width=${contentBoxSize.width}, height=${contentBoxSize.height}`)
                                // console.log("WidgetController:Resize observed. component =", $scope.component)
                                broadcastResizeDebounced()
                            }
                        }
                    })

                    function fullScreenChanged(e) {
                        const widgetEl = $element[0]
                        const isFullscreen = document.fullscreenElement == widgetEl
                        console.log("isFullScreen", isFullscreen)
                        $scope.isFullscreen = isFullscreen
                    }

                    function closeFullScreen() {
                        const widgetEl = $element[0]
                        if (document.fullscreenElement == widgetEl)
                            document.exitFullscreen()
                    }
                    $scope.closeFullScreen = closeFullScreen
                    if ($element) {
                        const widgetEl = $element[0]
                        const contentEl = widgetEl.closest(".grid-stack-item-content")
                        if (contentEl) { // widget is also used in A3 (with gridster) so make sure we are in grid-stack mode
                            resizeObserver.observe(contentEl)
                        }
                        widgetEl.addEventListener("fullscreenchange", fullScreenChanged)
                    }
                    $scope.$on('$destroy', function () {
                        console.log(`WidgetController: Destroy ${$scope.widgetid}`)
                        resizeObserver.disconnect()
                        resizeObserver = null
                        if ($element)
                            widgetEl.removeEventListener("fullscreenchange", fullScreenChanged)
                    })
                }]
            }
        }]);
