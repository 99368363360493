import swal from "sweetalert"
import { userProfile } from "@common/stores/userStore"
import { systemHubState } from "@common/services/systemHub"
import { connectSystemHubEvents, subscribeProxyEvents } from "@common/services/systemHubEvents"
import { HubConnectionState } from "@microsoft/signalr"
import { getUserFullProfile } from "@common/stores/userStore"
import * as employeeService from "@common/services/employeeService"

angular.module("DigiLean").controller('mainController', ['$scope', '$state', '$uibModal', '$translate', 'DigiLeanLangState', 'authService', 'suggestionService', 'navigationService', 'customerAccountService','froalaInsertDiagram',
    function                                             ($scope,   $state,   $uibModal,   $translate,   DigiLeanLangState,   authService,   suggestionService,   navigationService,   customerAccountService,  froalaInsertDiagram) {

        $scope.userProfile = {};
        
        $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
        $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
        $scope.hasA3Module = navigationService().hasModule("A3");
        $scope.hasCompetanceBallModule = navigationService().hasModule("COMPETENCEBALL");

        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasCompetanceBallModule = navigationService().hasModule("COMPETENCEBALL");
        });
                  
        $scope.connectionState = "Connecting"; 
        $scope.isLoaded = true; //hide loading screen
        // Make sure user is loggedIn.
        let isLoggingOut = false;

        let langCode = "en"
        userProfile.subscribe(up => {
            if (up.preferredLanguage)
                langCode = up.preferredLanguage
                
            DigiLeanLangState.setLang(langCode)
        })

        
        $scope.toggleConnectionState = function (state) {
            if ($scope.connectionState === state)
                return
            $scope.connectionState = state

            if (state === "Connected" || state === "loggedOut" || isLoggingOut) {
                // Hide overlay
                $('.overlay').css('width', '0%')
            } else {
                // show overlay
                $('.overlay').css('width', '100%')
            }
        }


        $scope.refresh = function() {
            location.reload(false);
        }

        $scope.showClock = function () {
            if ($scope.userSettings.navigation.showClock) {
                $scope.userSettings.navigation.showClock = false;
            } else {
                $scope.userSettings.navigation.showClock = true;
            }
            updateSettings();
        };

        $scope.showFullscreenOption = function () {
            if ($scope.userSettings.navigation.showFullScreen) {
                $scope.userSettings.navigation.showFullScreen = false;
            } else {
                $scope.userSettings.navigation.showFullScreen = true;
            }
            updateSettings();
        };
        $scope.showNewTask = function () {
            if ($scope.userSettings.navigation.showNewTask) {
                $scope.userSettings.navigation.showNewTask = false;
            } else {
                $scope.userSettings.navigation.showNewTask = true;
            }
            updateSettings();
        };
        $scope.showNewSuggestion = function () {
            if ($scope.userSettings.navigation.showNewSuggestion) {
                $scope.userSettings.navigation.showNewSuggestion = false;
            } else {
                $scope.userSettings.navigation.showNewSuggestion = true;
            }
            updateSettings();
        };
        $scope.showNewDeviation = function () {
            if ($scope.userSettings.navigation.showNewDeviation) {
                $scope.userSettings.navigation.showNewDeviation = false;
            } else {
                $scope.userSettings.navigation.showNewDeviation = true;
            }
            updateSettings();
        };
        $scope.showNewA3 = function () {
            if ($scope.userSettings.navigation.showNewA3) {
                $scope.userSettings.navigation.showNewA3 = false;
            } else {
                $scope.userSettings.navigation.showNewA3 = true;
            }
            updateSettings();
        };

        $scope.toggleNotificationBubbles = function () {
            // correct settings are bound we can just save the change
            updateSettings();
        };

        $scope.logOut = function () {
            authService.logOut()
            $scope.toggleConnectionState("loggedOut")
        }
        $scope.subscribe("UserLogOut", function() {
            console.log("requested to log out by server")
            $scope.logOut()
        })
        $scope.changePassword = function () {
            $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'changePassword.html',
                controller: 'changePasswordModalController'
            });
        };
        

        $scope.userConnectedCssClass = "";
        $scope.subscribe('userLoggedIn', function () {
            retrieveUserData()
            // connectToSignalR()
        })


        // Settings
        var updateSettings = function () {
            if (!$scope.userSettings) return;
            employeeService.updateUserSettings($scope.userSettings).then(function () {
                $scope.publish("UserSettingsChanged", $scope.userSettings);
            });
        };
        var loadSettings = function (userSettings) {
            if (userSettings == null) {
                userSettings = {
                    favorites: [],
                    navigation: {
                        showClock: true,
                        showFullScreen: true,
                        showNewTask: true,
                        showNewSuggestion: false,
                        showNewDeviation: false,
                        showNewA3: false
                    },
                    boards: []
                };

                updateSettings();
            }/*
            if (!userSettings.notifications) {
                userSettings.notifications = {
                    showInfoBubbles: true
                }
            }*/
            if (!userSettings.boards) {
                userSettings.boards = [];
            }
            $scope.userSettings = userSettings;
            if (!$scope.userSettings.navigation) {
                $scope.userSettings.navigation = {
                    showClock: true,
                    showFullScreen: true,
                    showNewTask: false,
                    showNewSuggestion: false,
                    showNewDeviation: false,
                    showNewA3: false
                };
            }
        };
        $scope.userSettings = null;
        $scope.subscribe('UserSettingsChanged', function (userSettings) {

            loadSettings(userSettings);
        });
        
        $scope.subscribe('ProfileImageUpdated', function (profileImage) {
            if ($scope.userProfile && $scope.userProfile.id == profileImage.userInfo.userId) {
                $scope.userProfile.profileImage = {};
                $scope.userProfile.profileImageUrl = profileImage.image.smallImageUrl;
            }
        });
        

        // TODO: what is the purpose of this?
        // $scope.subscribe('RefreshUserConnected', function () {
        //     if (!systemHub) return;
        //     if (systemHub.connection.state === 1) { // if connected than notify other clients
        //         console.log("refresh user connected");
        //         systemHub.SendUserConnectedRequest();
        //     }
        // });

        $scope.subscribe('UsersHavingAssetAccessChanged', function (userList) {
            // Check if current user is part of change, then refresh
            var currentUserId = authService.getCurrentUser().userId
            for (var i = 0; i < userList.length; i++) {
                if (currentUserId === userList[i]) {
                    retrieveUserData()
                    continue
                }
            }
        })


        $scope.userProfileLoaded = false;
        const retrieveUserData = function () {
            // Roles
            $scope.currentUser = authService.getCurrentUser();
            const profile = getUserFullProfile()
            navigationService().updateCache(profile);
            $scope.userProfileLoaded = true;
            $scope.userProfile = profile.user;
            if (profile.settings) {
                loadSettings(profile.settings);
            }
            else {
                loadSettings(null);
            }

            //IMPORTANT REMOVE NEXT LINE
            //$scope.isTrial = true; 
            $scope.accountEndDate = null;
            if(profile.isTrial){
                $scope.isTrial = true;
                $scope.accountEndDate = profile.subscriptionEndDate;
            }

            $scope.publish("UserAuthenticatedAndReady", profile);
            $scope.isAdmin = authService.hasRole("Admin");
                
            //IMPORTANT REMOVE NEXT LINE
            //profile.missingAccountSetup = true;    
            if(profile.missingAccountSetup){
                configureAccount();
            } else if(!profile.hasActiveSubscription){
                if(profile.isTrial){
                    openEndedTrialDialog();
                } else { // Previous customer may reactive
                    openContactSalesDialog(); // testing manual reactivation of account instead of openReactivateDialog();
                }
            }
            updateNavigationModules();
        
        };

        var updateNavigationModules = function () {
            $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
            $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
            $scope.hasA3Module = navigationService().hasModule("A3");
            $scope.hasCompetanceBallModule = navigationService().hasModule("COMPETENCEBALL");

            if (!$scope.hasImprovementModule) {
                $scope.userSettings.navigation.showNewSuggestion = false;
            }
            if (!$scope.hasDeviationModule) {
                $scope.userSettings.navigation.showNewDeviation = false;
            }
            if (!$scope.hasA3Module) {
                $scope.userSettings.navigation.showNewA3 = false;
            }
        };
        

        subscribeProxyEvents($scope)
        connectSystemHubEvents($scope)
        systemHubState.subscribe(state => {
            $scope.toggleConnectionState(state)
            if (state === HubConnectionState.Connected) {
                $scope.userConnectedCssClass = "userisconnected animated shake"
            }
            if (state === HubConnectionState.Disconnected)
                $scope.userConnectedCssClass = ""
        })

        function openEndedTrialDialog(){
            var buttonText = $translate.instant('ADMIN_CONTACT_SALES');
            const el = document.createElement('div')
            el.innerHTML = "<a href='mailto:sales@digilean.com?subject=Buy DigiLEAN' style='color:#0077B5'><button class='m-t-lg btn btn-lg btn-primary'><i class='fad  fa-envelope-open-dollar m-r-xs'></i><span>"+buttonText+"</span></button></a>"; //"Here's a <a href='http://google.com'>link</a>"

            swal({
                icon: "warning",
                allowOutsideClick: false,
                title: $translate.instant('ADMIN_TRIAL_HAS_ENDED'),
                dangerMode: true,
                buttons: {},
                closeOnClickOutside: false,
                closeOnEsc: false,
                closeOnConfirm: false,
                content: el,
            })           
        }
        function openContactSalesDialog(){
            var buttonText = $translate.instant('ADMIN_CONTACT_SALES');
            const el = document.createElement('div')
            el.innerHTML = "<a href='mailto:sales@digilean.com?subject=Reactivate DigiLEAN subscription' style='color:#0077B5'><button class='m-t-lg btn btn-lg btn-primary'><i class='fad  fa-envelope-open-dollar m-r-xs'></i><span>"+buttonText+"</span></button></a>"; //"Here's a <a href='http://google.com'>link</a>"

            swal({
                icon: "warning",
                allowOutsideClick: false,
                title: $translate.instant('ADMIN_SUBSCRIPTION_ENDED'),
                dangerMode: true,
                buttons: {},
                closeOnClickOutside: false,
                closeOnEsc: false,
                closeOnConfirm: false,
                content: el,
            })   
                      
        }

        function openReactivateDialog(){
            swal({
                title: $translate.instant('ADMIN_WELCOME_BACK'),
                text: $translate.instant('ADMIN_SUBSCRIPTION_ENDED'),
                icon: "warning",
                buttons: {reactivate: {text:$translate.instant('ADMIN_REACTIVATE_NOW'), className:"sa-digilean-button"}},
                dangerMode: true,
                closeOnClickOutside: false,
                closeOnEsc: false
            })
                .then((reactivate) => {
                    if (reactivate) {
                        swal($translate.instant('ADMIN_ACCOUNT_REACTIVATED'), {
                            icon: "success",
                            buttons: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "sa-digilean-button",
                                    closeModal: true,
                                }
                            }
                
                        }).then((ok) => {
                            customerAccountService().reactivateAccount().then(function (result) {
                            })
                        })
                    }
                })
        }
        function configureAccount() {
            
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'accountSetup.html',
                controller: 'accountSetupController',
                resolve: {
                    accountowner: function () {
                        return $scope.userProfile;
                    }

                }
            });

            modalInstance.result.then(function (config) {
                $state.go('myspace');
            }, function () {
            });
        }

        $scope.addFeedBack = function () {
            
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newFeedbackForm.html',
                controller: 'newFeedbackModalController',
                resolve: {
                    suggestion: function () {
                        return {
                            id: 0,
                            title: "",
                            text: "",
                        };
                    }

                }
            });

            // When user has created new suggestion
            modalInstance.result.then(function (suggestion) {
                suggestionService().addFeedBackToDigiLean(suggestion).then(function (savedsuggestion) {
                    $translate('NAV_FEEDBACK_SENT').then(function (msg) {
                        toastr.success(msg);
                    });
                });
            }, function () {
            });
        };

        froalaInsertDiagram.initialize()
    }]);

