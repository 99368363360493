import moment from "moment"
import * as customerService from "@common/services/customers/customerService"
import { translateLabelInstant } from "./language/DigiLeanLang";

export function getTranslationOptionsForMessage() {
    return {
        month: translateLabelInstant('COMMON_TIMEELAPSED_MONTH'),
        days: translateLabelInstant('COMMON_TIMEELAPSED_DAYS'),
        hours: translateLabelInstant('COMMON_TIMEELAPSED_HOURS'),
        mins: translateLabelInstant('COMMON_TIMEELAPSED_MINUTES'),
        seconds: translateLabelInstant('COMMON_TIMEELAPSED_SECONDS'),
        now: translateLabelInstant('COMMON_TIMEELAPSED_NOW')
    };
}


export function getTimeElapsed(startTime, options) {
    var then = moment.utc(startTime);
    var now = moment.utc();
    var diff = moment.duration(now.diff(then))._data;

    var defaults = {
        month: 'month(s) ago',
        days: 'd ago',
        hours: 'h ago',
        mins: 'm ago',
        seconds: 's ago',
        now: 'now'
    };
    var actual = $.extend({}, defaults, options || {});

    if (diff.months > 0 || diff.years > 0) {
        var mth = 0;
        if (diff.years > 0) {
            mth = diff.years * 12;
        }
        return mth + diff.months + " " + actual.month;
    }
    if (diff.days > 0) {
        return diff.days + actual.days;
    }
    if (diff.hours > 0) {
        return diff.hours + actual.hours;
    }
    if (diff.minutes > 0) {
        return diff.minutes + actual.mins;
    }
    if (diff.seconds > 0) {
        return diff.seconds + actual.seconds;
    }
    return actual.now;
}
export function getTimeframeLabel(timeframe) {
    switch (timeframe) {
        case "all":
            return translateLabelInstant("DASHBOARD_SUM_ALL_VALUES");
        case "last3years":
            return translateLabelInstant("DASHBOARD_LAST_3_YEARS");
        case "lastyear":
            return translateLabelInstant("DASHBOARD_LAST_YEAR");
        case "last12mth":
            return translateLabelInstant("DASHBOARD_LAST_12_MONTH");
        case "thisvslastyear":
            return translateLabelInstant("DASHBOARD_THIS_YEAR_VS_LAST_YEAR");
        case "thisfiscalvslastfiscalyear":
            return translateLabelInstant("DASHBOARD_THIS_FY_VS_LAST_FY");
        case "year":
            return translateLabelInstant("DASHBOARD_YTD");
        case "fiscalyear":
            return translateLabelInstant("DASHBOARD_FISCAL_YEAR");
        case "YTD":
            return translateLabelInstant("DASHBOARD_YTD");
        case "YTW":
            return translateLabelInstant("DASHBOARD_YTD");
        case "YTM":
            return translateLabelInstant("DASHBOARD_YTD");
        case "thisyear":
            return translateLabelInstant("DASHBOARD_THIS_YEAR");
        case "previousmonth":
            return translateLabelInstant("DASHBOARD_PREVIOUS_MONTH");
        case "30":
            return translateLabelInstant("COMMON_LAST_30_DAYS");
        case "month":
            return translateLabelInstant("DASHBOARD_CURRENT_MONTH");
        case "nextfourweeks":
            return translateLabelInstant("DASHBOARD_NEXT_FOUR_WEEKS");
        case "thisandnextweek":
            return translateLabelInstant("DASHBOARD_THIS_AND_NEXT_WEEK");
        case "previousisoweek":
            return translateLabelInstant("DASHBOARD_PREVIOUS_WEEK");
        case "isoweek":
            return translateLabelInstant("DASHBOARD_THIS_WEEK");
        case "thisweek":
            return translateLabelInstant("DASHBOARD_THIS_WEEK");
        case "yesterday":
            return translateLabelInstant("COMMON_YESTERDAY");
        case "day":
            return translateLabelInstant("COMMON_TODAY");
        case "custom":
            return translateLabelInstant("COMMON_CUSTOM");
        case "customStartDate":
            return translateLabelInstant("COMMON_CUSTOM");
        case "customEndDate":
            return translateLabelInstant("COMMON_CUSTOM");
        case "none":
            return translateLabelInstant("COMMON_NONE");
        case "last":
            return translateLabelInstant("COMMON_LAST_VALUE");
        default:
            return "";
    }
}

export function globalDatePickerOptions () {
    var dateOptions = {
        startingDay: 1 //ISO week
    };
    return dateOptions;
}

export function validateDate(date) {
    if (date && isNaN(date.getTime())) {
        return null;
    } else {
        return date;
    }
}

export function getUserTimeZone() {
    var options = Intl.DateTimeFormat().resolvedOptions();
    var timeZone = options.timeZone;
    return timeZone;
}

export function getTimeZoneOffset() {
    var timeNow = new Date();
    var hoursOffset = timeNow.getTimezoneOffset() / 60 * (-1);
    return hoursOffset;
}

export function getDurationText(seconds) {
    var minutes = seconds / 60;
    var hours = parseInt(minutes / 60);
    var restminutes = minutes % 60;
    var hourText = "";
    var minutesText = "";
    if (hours > 0) hourText = hours + " h ";
    if (restminutes > 0) minutesText = restminutes + " min";
    return hourText + minutesText;
}

export function getUTCDate() {
    return moment.utc().startOf('day');
}

export function getMonths () {
    var months = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
        { id: 12 },
    ];
    for (let index = 0; index < months.length; index++) {
        const month = months[index];
        month.name = getMonthLabel(month.id);
        month.fullName = moment.months(index);
    }
    return months;
}

export function getMonthLabel(monthNo) {
    switch (monthNo) {
        case 1:
            return translateLabelInstant('COMMON_MONTH_JAN');
        case 2:
            return translateLabelInstant('COMMON_MONTH_FEB');
        case 3:
            return translateLabelInstant('COMMON_MONTH_MAR');
        case 4:
            return translateLabelInstant('COMMON_MONTH_APR');
        case 5:
            return translateLabelInstant('COMMON_MONTH_MAY');
        case 6:
            return translateLabelInstant('COMMON_MONTH_JUN');
        case 7:
            return translateLabelInstant('COMMON_MONTH_JUL');
        case 8:
            return translateLabelInstant('COMMON_MONTH_AUG');
        case 9:
            return translateLabelInstant('COMMON_MONTH_SEP');
        case 10:
            return translateLabelInstant('COMMON_MONTH_OCT');
        case 11:
            return translateLabelInstant('COMMON_MONTH_NOV');
        case 12:
            return translateLabelInstant('COMMON_MONTH_DEC');
        default:
            return translateLabelInstant('COMMON_NOT_SPECIFIED');
    }
}

export function getWeeksInMonth(year, monthNumber, locale) {
    if(locale){
        moment.locale(locale);
    }
    var startOfMonth = moment([year, monthNumber - 1]).toDate();
    var endOfMonth = moment(startOfMonth).endOf('month').toDate();

    var startWeekNumber = moment(startOfMonth).isoWeek();
    var endWeekNumber = moment(endOfMonth).isoWeek();
    var totalWeeksInMonth = endWeekNumber - startWeekNumber;
    if (endWeekNumber < startWeekNumber) { // If startweek is 52 or 53
        var numberOfWeeks = moment(startOfMonth).isoWeeksInYear();
        if (monthNumber == 1) { // Handle when january, use previous year to calculate
            numberOfWeeks = moment([year - 1, 1]).isoWeeksInYear();
        }
        totalWeeksInMonth = (numberOfWeeks + endWeekNumber) - startWeekNumber;
    }
    var weeks = [];
    var activeWeekNumber = startWeekNumber;
    var activeYear = year;
    for (let index = 0; index <= totalWeeksInMonth; index++) {
        var weekYear = activeYear;

        if (monthNumber == 1 && activeWeekNumber >= 52) {
            weekYear = activeYear - 1;
        }

        var week = getCurrentWeek(activeWeekNumber, weekYear);
        var startDateYear = moment(week.startDate).year();
        var endDateYear = moment(week.endDate).year();
        var indexNumber = week.weekNumber;
        if (startDateYear > endDateYear) {
            // We need to handle if last week in month is actually
            indexNumber = 0;
        }
        if (endDateYear > startDateYear) {
            // We need to handle if last week in month is actually week 1 the next year, by adding count to the last. But could also be week 53 of current year
            indexNumber = moment(week.startDate).isoWeeksInYear();
            if (indexNumber <= 1) {
                indexNumber += 1;
            }
        }
        if (monthNumber == 1 && activeWeekNumber >= 52) {
            indexNumber = 0;
        }

        weeks.push({
            number: activeWeekNumber,
            indexNumber: indexNumber,
            startDate: week.startDate,
            startDateYear: startDateYear,
            endDate: week.endDate,
            endDateYear: endDateYear,
            days: week.weekDays
        });
        // Next week
        var firstDayNextWeek = moment(week.endDate).add(1, 'days');
        activeYear = firstDayNextWeek.isoWeekYear();
        activeWeekNumber = firstDayNextWeek.isoWeek();
    }
    return weeks;
}

export function addTimeElapsedForMsg (msg) {
    msg.timeElapsed = getTimeElapsed(msg.sendTime, getTranslationOptionsForMessage());
}
export function getTimeFromFullDate(date) {
    return moment.utc(date).local().format("HH:mm");
}
export function isToday(date) {
    var today = moment();
    var inputDate = moment(date);
    var isSame = today.isSame(inputDate, 'day');
    return isSame;
}
export function isDateInWeek (date, week) {
    date = moment.utc(date).toDate();
    return date >= week.startDate && date <= week.endDate;
}
export function parseTime (s) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
}
function joinDateAndTime(date, time) {
    var c = time.split(':');
    var momentDate = moment(date).startOf('day');
    momentDate.add(parseInt(c[0]), 'hours');
    momentDate.add(parseInt(c[1]), 'minutes');
    var toDate = momentDate.toDate();
    return toDate;

}
export function getDuration(startTime, endTime) {
    var minutes = parseTime(endTime) - parseTime(startTime);
    var seconds = minutes * 60;
    return {
        durationInSeconds: seconds,
        durationText: getDurationText(seconds)
    };
}

// How many days spans between startTime and endTime. e.g. startTime=01.01.2024, endTime=02.01.2024 returns 2 
export function getDurationInDays(startTime, endTime) {
    // Make sure we flatten time when comparing.
    var start = moment(startTime).startOf('day');
    var end = moment(endTime).startOf('day');
    var duration = end.diff(start, 'days', true);
    duration += 1; // Need to include current day
    return duration;
}

export function getDateInWeek(weekDay, days) {
    for (var i = 0; i < days.length; ++i) {
        if (weekDay === days[i].day) {
            return days[i].date;
        }
    }
    return null;
}

export function getWeekDayNameFromDate (date) {
    if (!date) return null;
    var number = moment(date).format('d');
    // Based on Monday = 1;
    
    switch (parseInt(number)) {
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";                            
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        case 0:
            return "Sunday";

    }
}

export function getCurrentWeek(weekNumber, year) {
    var startOfWeek, endOfWeek;
    if (weekNumber) {
        startOfWeek = moment().isoWeekYear(year).isoWeek(weekNumber).isoWeekday(1);
        endOfWeek = moment(startOfWeek).endOf('isoweek');
    } else {
        startOfWeek = moment().startOf('isoweek');
        endOfWeek = moment().endOf('isoweek');
    }
    var startDate = startOfWeek
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0).toDate();

    var endDate = endOfWeek.hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999).toDate();
    return {
        weekNumber: weekNumber,
        startDate: startDate,
        endDate: endDate,
        weekDays: [
            { number: 1, day: "Monday", translated: translateLabelInstant('COMMON_MONDAY'), date: moment(startDate).toDate() },
            { number: 2, day: "Tuesday",  translated: translateLabelInstant('COMMON_TUESDAY'), date: moment(startDate).add(1, 'days').toDate() },
            { number: 3, day: "Wednesday",  translated: translateLabelInstant('COMMON_WEDNESDAY'), date: moment(startDate).add(2, 'days').toDate() },
            { number: 4, day: "Thursday",  translated: translateLabelInstant('COMMON_THURSDAY'), date: moment(startDate).add(3, 'days').toDate() },
            { number: 5, day: "Friday",  translated: translateLabelInstant('COMMON_FRIDAY'), date: moment(startDate).add(4, 'days').toDate() },
            { number: 6, day: "Saturday",  translated: translateLabelInstant('COMMON_SATURDAY'), date: moment(startDate).add(5, 'days').toDate() },
            { number: 7, day: "Sunday",  translated: translateLabelInstant('COMMON_SUNDAY'), date: moment(startDate).add(6, 'days').toDate() }
        ]
    }
    //NB endOfWeek og Sunday har ulike tidspunkter, men skal egentlig representere samme sluttdag. TODO avgjøre om vi skal rydde opp i dette og kjøre et databaseskript som endrer datoer på eksisterende oppgaver
}

/*
@period: e.g. 'year', 'month', '30', 
*/
export function getTimePeriod(period, fiscalmonthSettings) {
    if(period === "fiscalyear"){
        var companyfiscalmonth = customerService.getDefaultFiscalmonth()
        if (fiscalmonthSettings) {
            companyfiscalmonth = fiscalmonthSettings;
        }
        companyfiscalmonth = companyfiscalmonth - 1; //convert to moment month

        if(companyfiscalmonth > moment().month()){
            startDate = moment().subtract(1, 'year').month(companyfiscalmonth).startOf("month");
        } else {
            startDate = moment().month(companyfiscalmonth).startOf("month");
        }
        endDate = moment(startDate).add(12, "month").subtract(1, 'days').endOf("day");
        var timePeriod = {
            from: startDate.utc().toDate().toJSON(),
            to: endDate.utc().toDate().toJSON()
        };
        return timePeriod;
    } else if(period === "last3fiscalyears"){
        var companyfiscalmonth = customerService.getDefaultFiscalmonth()
        if (fiscalmonthSettings) {
            companyfiscalmonth = fiscalmonthSettings;
        }
        companyfiscalmonth = companyfiscalmonth - 1; //convert to moment month

        if(companyfiscalmonth > moment().month()){
            startDate = moment().subtract(3, 'year').month(companyfiscalmonth).startOf("month");
        } else {
            startDate = moment().subtract(2, 'year').month(companyfiscalmonth).startOf("month");
        }
        endDate = moment(startDate).add(36, "month").subtract(1, 'days').endOf("day");
        var timePeriod = {
            from: startDate.utc().toDate().toJSON(),
            to: endDate.utc().toDate().toJSON()
        };
        return timePeriod;
    } else if(period === "thisfiscalvslastfiscalyear"){
        var companyfiscalmonth = customerService.getDefaultFiscalmonth()
        if (fiscalmonthSettings) {
            companyfiscalmonth = fiscalmonthSettings;
        }
        companyfiscalmonth = companyfiscalmonth - 1; //convert to moment month

        if(companyfiscalmonth > moment().month()){
            startDate = moment().subtract(2, 'year').month(companyfiscalmonth).startOf("month");
        } else {
            startDate = moment().subtract(1, 'year').month(companyfiscalmonth).startOf("month");
        }
        endDate = moment(startDate).add(24, "month").subtract(1, 'days').endOf("day");
        var timePeriod = {
            from: startDate.utc().toDate().toJSON(),
            to: endDate.utc().toDate().toJSON()
        };
        return timePeriod;
    } else {
        var endDate = moment().endOf('day');
        var startDate = moment().startOf('day');

        if (period === 'year' || period === 'quarter' || period === 'month' || period === 'isoweek' || period === 'day') {
            startDate = moment().startOf(period);
            endDate = moment(startDate).endOf(period);
        } else if (period === "YTD") {
            startDate = moment().startOf('year');
        } else if (period === "YTW") {
            startDate = moment().startOf('year');
            endDate = moment().endOf('isoweek');
        } else if (period === "YTM") {
            startDate = moment().startOf('year');
            endDate = moment().endOf('month');
        } else if (isNumber(period)) {
            startDate = moment(startDate).subtract(period, 'days');
        } else if (period === "last3years") {
            endDate = moment(startDate).endOf("year");
            startDate = moment().subtract(2, 'year').startOf("year");
        } else if (period === "last12mth") {
            startDate = moment().subtract(12, 'month').startOf("month");
        } else if (period === "lastyear") {
            startDate = moment().subtract(1, 'year').startOf("year");
            endDate = moment(startDate).endOf("year");
        } else if (period === "month") {
            startDate = moment().startOf("month");
            endDate = moment(startDate).endOf("month");                     
        } else if (period === "nextfourweeks") {
            var startOfWeek = moment().startOf("isoweek");
            startDate = startOfWeek.add(7, 'days');
            endDate = moment(startDate).add(3, 'week').endOf("isoweek");
        }else if (period === "previousisoweek") {
            var startOfWeek = moment().startOf("isoweek");
            startDate = startOfWeek.subtract(7, 'days');
            endDate = moment(startDate).endOf("isoweek");
        } else if (period === "thisweek") {
            startDate = moment().startOf("isoweek");
            endDate = moment(startDate).endOf("isoweek");
        } else if (period === "thisandnextweek") {
            startDate = moment().startOf("isoweek");
            endDate = moment(startDate).add(7, 'days').endOf("isoweek");
        } else if (period === "thisyear") {
            startDate = moment().startOf("year");
            endDate = moment(startDate).endOf("year");
        } else if (period === "nextyear") {
            startDate = moment().startOf("year").add(1, 'year');
            endDate = moment(startDate).endOf("year");
        } else if (period === "yesterday") {
            startDate = moment().subtract(1, 'day').startOf("day");
            endDate = moment(startDate).endOf("day");
        } else if (period === "previousmonth") {
            startDate = moment().subtract(1, 'month').startOf("month");
            endDate = moment(startDate).endOf("month");
        } else if (period === "all") {
            startDate = moment("2013-01-01");
            endDate = moment().add(7, 'year').endOf("year");
        } else if (period === "nextquarter") {
            startDate = moment().startOf("quarter").add(1, 'quarter');
            endDate = moment(startDate).endOf("quarter");
        } else if (period === "thisvslastyear") {
            endDate = moment(startDate).endOf("year");
            startDate = moment().subtract(1, 'year').startOf("year");
        }
        var timePeriod = {
            from: startDate.utc().toDate().toJSON(),
            to: endDate.utc().toDate().toJSON()
        };
        return timePeriod;
    }
}

/*
@daysBeforeNow: e.g. '7'
@daysBeforeNow: e.g. '14' 
*/
export function getTimePeriodBeforeAfter(daysBeforeNow, daysAfterNow) {
    var endDate = moment().endOf('day');
    var startDate = moment().startOf('day');

    startDate = moment(startDate).subtract(daysBeforeNow, 'days');
    endDate = moment(endDate).add(daysAfterNow, 'days');

    var timePeriod = {
        from: startDate.utc().toDate().toJSON(),
        to: endDate.utc().toDate().toJSON()
    };
    return timePeriod;
}
export function isNumber(o) {
    return !isNaN(o - 0) && o !== null && o !== "" && o !== false;
}

export function isWithinTimePeriod(startTime, endTime, timePeriod){
    var result = false;
    if(startTime && endTime && timePeriod){ //valid input
        var timeperiodfrom = moment(timePeriod.from);
        var timeperiodto = moment(timePeriod.to);
        if(moment(timeperiodfrom).isBefore(startTime)
            && moment(startTime).isBefore(timeperiodto)){
            return true;
        }
        if(moment(timeperiodfrom).isBefore(endTime)
            && moment(endTime).isBefore(timeperiodto)){
            return true;
        }
        if(moment(startTime).isBefore(timeperiodfrom)
            && moment(timeperiodto).isBefore(endTime)){
            return true;
        }
        if(moment(timeperiodfrom).isSame(startTime)){
            return true;
        }
    }
    return result;
}
let currentDate = new Date()
let dayTimerInterval = 0
export function startDayTimer($scope) {
    if (!dayTimerInterval) {// check if already running

        dayTimerInterval = setInterval(function () {
            const currentDay = currentDate.getDay()
            const dateNow = new Date()
            const dayNow = dateNow.getDay()
            
            if (currentDay !== dayNow) {
                console.log("publish new day", dateNow)
                $scope.publish("NewDay", dateNow)    
            }
        }, 10000)
    }
}

